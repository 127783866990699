<template>
  <a-select
    allowClear
    style="width: 100%"
    :disabled="disabled"
    v-model="selectValue"
    :placeholder="selfPlaceholder"
    :not-found-content="loading ? undefined : null"
    @change="onChange"
    @focus="fetchMethodList"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in methodList" :value="item.code" :key="item.code">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'

export default {
  props: ['placeholder', 'disabled', 'code'],
  data() {
    return {
      methodList: [],
      loading: false,
      selectValue: undefined,
    }
  },
  computed: {
    selfPlaceholder() {
      return this.placeholder || this.$t('请选择结算方式')
    },
  },
  watch: {
    code: {
      immediate: true,
      handler() {
        this.fetchMethodList()
        this.setDefault()
      },
    },
  },
  methods: {
    clear() {
      this.selectValue = undefined
    },
    async fetchMethodList() {
      if (this.methodList.length) return
      this.loading = true
      let res = await http({
        url: api.getSettlementMethodList,
        type: 'post',
        data: {},
      })
      this.loading = false
      this.methodList = res.result.list
      this.setDefault()
    },
    setDefault() {
      if (this.code) {
        this.onChange(this.code)
      }
    },
    onChange(val) {
      this.selectValue = val
      if (val) {
        let data = this.methodList.find((item) => item.code === val)
        this.$emit('selected', data)
        this.$emit('update:code', val)
      }
    },
  },
}
</script>
