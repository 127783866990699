<template>
  <div class="create-batch-check-order-job">
    <breadcrumb style="margin-left: 15px; margin-bottom: -5px"></breadcrumb>
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="OrderStatusFlow" @change="changeTabs">
          <a-tab-pane key="ArrivalDetail" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`金额信息`)"></a-tab-pane>
          <a-tab-pane key="ProductInfo" :tab="$t(`明细`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="checkOrder"
            :text="$t('审核')"
            :params="{
              docType: 'PaymentRequest',
              idList: [id],
            }"
            @success="init"
          />
          <PushBtn
            v-if="formData.status === 'WAIT_PAY' || formData.status === 'PART_PAID'"
            class="ml10"
            type="PaymentRequest"
            :selectedRows="[formData]"
          />
          <OperateBtn
            v-if="isDetail && ['WAIT_CHECK', 'WAIT_PAY'].includes(formData.status)"
            api="cancelPaymentRequest"
            :text="$t('作废')"
            type="danger"
            :params="{
              idList: [id],
            }"
            @success="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <div id="ArrivalDetail" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('基本信息') }}</span>
                  <span class="fz14 ml10 text-link" v-if="isDetail" @click="copyOrder">
                    {{ $t('复制单据') }}
                  </span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款申请单号	`)">
                    <a-input disabled v-model="formData.paymentRequestSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款组织`)" required>
                    <SelectSupplier
                      ref="selectSupplier"
                      :placeholder="$t(`收款组织`)"
                      :code="formData.supplierCode"
                      :disabled="isDisabled"
                      @supplierIndexChange="supplierIndexChange"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`请款时间`)" required>
                    <DatePicker
                      :needhms="true"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.applyTime"
                      @choose="chooseDate"
                      :disabled="isDisabled"
                      :placehold="$t(`请款时间`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <CommonQuerySelect
                      :init="true"
                      api="getBusinessUnitList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据类型`)" required>
                    <CommonSelect
                      :placeholder="$t(`单据类型`)"
                      :list="payment_request_type"
                      :code.sync="formData.orderType"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`请款事由`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.applyReason"
                      :placeholder="$t(`请款事由`)"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input
                      :disabled="isDisabled"
                      v-model="formData.remark"
                      :placeholder="$t(`备注`)"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6" v-if="isDetail">
                  <a-form-model-item :label="$t(`状态`)">
                    <a-input
                      disabled
                      v-model="paymentRequestStatusMapping[formData.status]"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <div id="AmountInfo" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('金额信息') }}</span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算货币`)" required>
                    <SelectCurrency
                      :code.sync="formData.settlementCurrency"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`应付金额`)">
                    <PriceInput
                      disabled
                      :value="formData.payableAmount"
                      :currency="formData.settlementCurrency"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`付款货币`)" required>
                    <SelectCurrency :code.sync="formData.paymentCurrency" :disabled="isDisabled" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`预计应付金额`)">
                    <PriceInput
                      disabled
                      :value="formData.expectedPaymentAmount"
                      :currency="formData.paymentCurrency"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`请款金额`)" required>
                    <PriceInput
                      :disabled="isDisabled"
                      :value.sync="formData.applyAmount"
                      :currency="formData.settlementCurrency"
                      :placeholder="$t(`请款金额`)"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </a-form-model>
          <div id="ProductInfo" class="modalBox">
            <div>
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  {{ $t('明细') }}
                </div>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="addLineHandle">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml20" @click="deleteLineHandle">
                    {{ $t('删行') }}
                  </span>
                </div>
              </div>
              <CommonTable
                :columns="columns"
                :dataSource="showTableList"
                :scroll="{ x: 1200 }"
                :showPagination="false"
                :showSelect="!isDisabled"
                :selectedRowKeys.sync="selectedRowKeys"
              >
                <template v-slot:no="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:paymentPurpose="{ record }">
                  <CommonSelect
                    :disabled="isDisabled"
                    :list="payment_purpose"
                    :code.sync="record.paymentPurpose"
                    :currency="formData.settlementCurrency"
                  />
                </template>
                <template v-slot:applyAmount="{ record }">
                  <PriceInput
                    :value.sync="record.applyAmount"
                    :currency="formData.settlementCurrency"
                    :disabled="isDisabled"
                    @blur="(val) => handleChange(val, record)"
                  />
                </template>
                <template v-slot:payableAmount="{ text }">
                  <PriceInput
                    :value="text"
                    :currency="formData.settlementCurrency"
                    :showTxt="true"
                  />
                </template>
                <template v-slot:expectedPaymentAmount="{ text }">
                  <PriceInput :value="text" :currency="formData.paymentCurrency" :showTxt="true" />
                </template>
                <template v-slot:dueDate="{ record }">
                  <DatePicker
                    :needhms="false"
                    style="background-color: #ffffff; width: 100%"
                    :init-date-time="record.dueDate"
                    @choose="
                      (data) => {
                        chooseDueDate(record, data)
                      }
                    "
                    :disabled="isDisabled"
                  />
                </template>
                <template v-slot:settlementMethod="{ record }">
                  <SelectSettlementMethod
                    :code.sync="record.settlementMethod"
                    :disabled="isDisabled"
                  />
                </template>
              </CommonTable>
            </div>
          </div>
        </section>
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <a-modal :title="$t('单据转换报告')" v-model="resultVisible" width="800px">
      <CommonTable :columns="resultColumns" :dataSource="resultList" :showPagination="false">
        <template v-slot:no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:status="{ record }">
          <span class="text-success" v-if="record.success">
            {{ $t('成功') }}
          </span>
          <span class="text-error" v-else>
            {{ $t('失败') }}
          </span>
        </template>
      </CommonTable>
      <template slot="footer">
        <a-button @click="resultVisible = false">{{ $t('关闭') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import SelectSupplier from '@component/selectSupplier'
import SelectCurrency from '@component/selectCurrency'
import CommonSelect from '@component/commonSelect'
import CommonTable from '@component/commonTable'
import PushBtn from '@component/pushBtn'
import PriceInput from '@component/priceInput'
import { add, subtract } from '@/common/number'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import SelectSettlementMethod from '@component/selectSettlementMethod'
import deepClone from '@/common/deepClone'
import { convertKeysToCamelCase } from '@/common'
import debounce from '@/common/debounce'
export default {
  name: 'paymentRequestDetail',
  components: {
    SelectSupplier,
    DatePicker,
    SelectCurrency,
    CommonSelect,
    CommonTable,
    BottomBtns,
    PushBtn,
    PriceInput,
    SelectSettlementMethod,
  },
  data() {
    return {
      id: '',
      loading: false,
      visible: false,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑
      saveLoading: false,
      draftLoading: false,
      detailList: [],
      selectedRowKeys: [],
      formData: {
        supplierCode: undefined,
        amountWithoutTax: undefined,
        payableAmount: undefined,
        settlementCurrency: 'CNY',
        paymentCurrency: 'CNY',
        status: 'DRAFT',
      },
      purchaseAllQuantity: 0,
      purchaseAllAmount: 0,
      totalPayableAmount: 0,
      resultVisible: false,
      resultList: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['payment_request_type', 'payment_purpose']),
    ...mapGetters(['paymentRequestStatusMapping', 'currencySymbolMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    columns() {
      let list = [
        {
          title: this.$t('付款用途'),
          align: 'center',
          dataIndex: 'paymentPurpose',
          width: 120,
          scopedSlots: {
            customRender: 'paymentPurpose',
          },
        },
        {
          title: this.$t('应付金额'),
          align: 'center',
          dataIndex: 'payableAmount',
          width: 150,
          scopedSlots: {
            customRender: 'payableAmount',
          },
        },
        {
          title: this.$t('申请金额'),
          align: 'center',
          dataIndex: 'applyAmount',
          width: 120,
          scopedSlots: {
            customRender: 'applyAmount',
          },
        },
        {
          title: this.$t('预计付款金额'),
          align: 'center',
          dataIndex: 'expectedPaymentAmount',
          width: 120,
          scopedSlots: {
            customRender: 'expectedPaymentAmount',
          },
        },
        {
          title: this.$t('到期日'),
          align: 'center',
          dataIndex: 'dueDate',
          width: 120,
          scopedSlots: {
            customRender: 'dueDate',
          },
        },
        {
          title: this.$t('结算方式'),
          align: 'center',
          dataIndex: 'settlementMethod',
          width: 120,
          scopedSlots: {
            customRender: 'settlementMethod',
          },
        },
      ]
      if (this.isDetail) {
        list.splice(
          -2,
          0,
          ...[
            {
              title: this.$t('已锁定金额'),
              dataIndex: 'lockAmount',
              customRender: (text) => this.showPrice(text),
            },
            {
              title: this.$t('未锁定金额'),
              dataIndex: 'unLockAmount',
              customRender: (text, record) =>
                this.showPrice(subtract(record.approvedAmount, record.lockAmount || 0)),
            },
          ]
        )
      }
      return list
    },
    scrollHeight() {
      let height = window.innerHeight - 300
      return height * this.$store.state.rate + 'px'
    },
    resultColumns() {
      return [
        {
          title: this.$t('源单数据'),
          dataIndex: 'orderSn',
        },
        {
          title: this.$t('下推状态'),
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: this.$t('消息'),
          dataIndex: 'desc',
        },
      ]
    },
    showTableList() {
      return this.detailList.filter((item) => item.showStatus !== -1)
    },
  },
  methods: {
    init() {
      this.isCopy = false
      this.isEdit = false
      this.isDetail = false
      if (this.$route.query.id) {
        this.id = this.$route.query.id
      } else {
        Object.assign(this.$data, this.$options.data())
      }
      this.getDetailInfo()
    },
    showPrice(num) {
      if (num) {
        return this.$currencySymbol(num, this.formData.paymentCurrency)
      }
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      this.formData.paymentCurrency = value.currency
    },
    scrollToBottom() {
      this.$nextTick(() => {
        document.querySelector('.fixContent').scrollTo(0, 2000)
      })
    },
    supplierIndexChange(val) {
      this.formData.supplierName = val.supplierName
      this.formData.supplierCode = val.supplierCode
      if (!this.formData.businessUnitCode) {
        this.formData.businessUnitCode = val.businessUnitCode
        this.formData.businessUnitName = val.businessUnitName
      }
    },
    async getDetailInfo() {
      let paymentRequestFormdata = localStorage.getItem('paymentRequestFormdata'),
        pushPayableList = localStorage.getItem('pushPayableList')
      if (this.$route.query.id) {
        this.loading = true
        this.isDetail = true
        let res = await http({
          url: api.getPaymentRequestDetail,
          type: 'post',
          data: {
            id: this.id,
          },
        })
        let result = convertKeysToCamelCase(res.result)
        this.detailList = deepClone(result.detailList)
        if (this.detailList.length === 0) {
          this.addLineHandle()
        }
        let formData = result
        this.formData = formData
        this.calcAllAmount()
        if (result.status === 'DRAFT') {
          this.isEdit = true
        }
      } else if (paymentRequestFormdata) {
        //复制
        this.isCopy = true
        let data = JSON.parse(paymentRequestFormdata)
        console.log(data)
        this.formData = data
        this.formData.paymentRequestSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.detailList = deepClone(this.formData.detailList)
        delete this.formData.detailList
        localStorage.removeItem('paymentRequestFormdata')
      } else if (pushPayableList) {
        this.isCopy = true
        //应付单下推过来
        let data = this.getdetailList(JSON.parse(pushPayableList))
        this.detailList = deepClone(data)
        localStorage.removeItem('pushPayableList')
        this.calcAllAmount()
      }
      this.loading = false
    },
    // 待核销/部分核销状态 planList里面status: "NORMAL"可以下推生成付款申请单、付款单
    getdetailList(list) {
      this.formData.supplierCode = list[0].supplierCode
      this.formData.supplierName = list[0].supplierName
      this.formData.businessUnitCode = list[0].businessUnitCode
      this.formData.businessUnitName = list[0].businessUnitName
      this.formData.settlementCurrency = list[0].settlementCurrency
      this.formData.paymentCurrency = list[0].functionalCurrency
      this.formData.dueDate = list[0].dueDate
      let result = [],
        data = []
      list.forEach((item) => {
        if (!['WAIT_WRITTEN_OFF', 'PART_WRITTEN_OFF'].includes(item.status)) {
          result.push({
            orderSn: item.orderSn,
            success: false,
            desc: this.$t('不符合条件: 请选择待核销、部分核销单据'),
          })
        } else {
          let planResult = [],
            hasError = false
          item.planList.forEach((elem, index) => {
            let unLockAmount = subtract(elem.payableAmount, elem.lockAmount)
            if (elem.status !== 'NORMAL') {
              planResult.push({
                orderSn: item.orderSn + this.$t('付款计划第X行', { X: index + 1 }),
                success: false,
                desc: this.$t('不符合条件: 付款计划状态需为未冻结'),
              })
              hasError = true
            } else if (unLockAmount <= 0) {
              planResult.push({
                orderSn: item.orderSn + this.$t('付款计划第X行', { X: index + 1 }),
                success: false,
                desc: this.$t('不符合条件: 付款计划未锁定金额需大于0'),
              })
              hasError = true
            } else {
              data.push({
                dueDate: moment().format('YYYY-MM-DD'),
                payableAmount: elem.payableAmount,
                applyAmount: elem.payableAmount,
                expectedPaymentAmount: elem.payableAmount,
                paymentPurpose: elem.isPrepay ? 'PREPAYMENT' : 'PURCHASE',
                referDocDetailId: elem.id,
                referDocId: item.id,
                referDocSn: item.orderSn,
                referDocType: 'PayableOrder',

                sourceDocDetailId: elem.id,
                sourceDocId: item.id,
                sourceDocSn: item.orderSn,
                sourceDocType: 'PayableOrder',
              })
              planResult.push({
                orderSn: item.orderSn + this.$t('付款计划第X行', { X: index + 1 }),
                success: true,
                desc: this.$t('符合条件'),
              })
            }
          })
          if (hasError) {
            result = [...result, ...planResult]
          } else {
            result.push({
              orderSn: item.orderSn,
              success: true,
              desc: this.$t('符合条件'),
            })
          }
        }
      })
      this.resultList = result
      this.resultVisible = true
      return data
    },
    chooseDate(data) {
      this.formData.applyTime = data.data
    },
    handleChange(value, record) {
      if (+value === 0) {
        value = ''
      }
      this.$set(record, 'applyAmount', value)
      this.$set(record, 'expectedPaymentAmount', value)
      this.calcAllAmount()
    },
    calcAllAmount() {
      let applyAmount = 0,
        expectedPaymentAmount = 0
      this.detailList.forEach((item) => {
        applyAmount = add(applyAmount, item.applyAmount || 0)
        expectedPaymentAmount = add(expectedPaymentAmount, item.expectedPaymentAmount || 0)
      })
      this.formData.applyAmount = applyAmount
      this.formData.payableAmount = applyAmount
      this.formData.expectedPaymentAmount = expectedPaymentAmount
    },
    verification() {
      this.detailList.forEach((res, index) => {
        if (!res.paymentPurpose) {
          this.$warning({
            title: this.$t(`layer.Warning`),
            content: this.$t(`第X行请选择付款用途`, { X: index + 1 }),
          })
          throw Error()
        }
        if (!res.applyAmount) {
          this.$warning({
            title: this.$t(`layer.Warning`),
            content: this.$t(`第X行请填写申请金额`, { X: index + 1 }),
          })
          throw Error()
        }
      })
      return Promise.resolve()
    },
    backForm(back = 0) {
      if (back) {
        this.$router.go(-1)
        return
      }
      this.$router.push({ name: 'paymentRequestList' })
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    copyOrder() {
      this.formData.detailList = this.detailList
      let data = JSON.stringify(this.formData)
      localStorage.setItem('paymentRequestFormdata', data)
      this.$router.push('/payableManage/paymentRequestDetail')
    },
    chooseDueDate(record, data) {
      record.dueDate = data.data
    },
    onSubmit: debounce(async function (status) {
      if (status !== 'DRAFT') {
        if (!this.formData.supplierName) {
          this.$warning({
            title: this.$t(`layer.Warning`),
            content: this.$t(`请选择收款组织`),
          })
          return
        }
        if (!this.formData.applyAmount) {
          this.$warning({
            title: this.$t(`layer.Warning`),
            content: this.$t(`请填写请款金额`),
          })
          return
        }
        await this.verification()
      }
      const data = deepClone(this.formData)
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList = deepClone(this.detailList)
      data.merchantCode = localStorage.getItem('merchantCode')
      await http({
        url: api.createPaymentRequest,
        data: {
          paymentRequestReqVO: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`layer.Success`),
              content: this.$t(`layer.SavingSucceeded`),
            })
            if (this.isCopy) {
              this.backForm()
            } else {
              this.init()
            }
          } else {
            this.$error({
              title: this.$t(`layer.Error`),
              content: this.$t(`layer.CreateFailed`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    addLineHandle() {
      this.detailList.push({
        dueDate: moment().format('YYYY-MM-DD'),
      })
    },
    deleteLineHandle() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.detailList
        .filter((item) => item.showStatus !== -1)
        .forEach((item, index) => {
          if (this.selectedRowKeys.includes(index)) {
            this.$set(item, 'showStatus', -1)
            item.payableAmount = 0
            item.applyAmount = 0
            item.expectedPaymentAmount = 0
          }
        })
      this.selectedRowKeys = []
      this.calcAllAmount()
    },
  },
}
</script>

<style lang="scss" scoped>
.statusBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-table-heard:before {
  content: '*';
  color: red;
}

.form-content {
  padding: 20px 5%;
}
.ant-form-item {
  margin-bottom: 0;
}
.search-group {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3px;
  .add {
    position: absolute;
    display: block;
    right: -45px;
  }
}
.goods-list {
  flex-wrap: wrap;
  .goods-item {
    line-height: normal;
    margin-right: 6px;
    margin-bottom: 4px;
    white-space: nowrap;
  }
}
.infoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fixContent {
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0;
}
.node-modal {
  font-size: 15px;
  padding-top: 10px;
  text-align: center;
}
.content-overflow {
  width: 100%;
  overflow: auto;
  display: flex;
}
.left-div {
  flex: 1;
}
.right-div {
  background-color: transparent;
  font-size: 12px;
  width: 300px;
  height: 591px;
  overflow: auto;
}
.formContentGoods {
  /* padding: 10px 15px; */
  margin-bottom: 16px;
  background-color: white;
  color: #333333;
  font-size: 14px;
}
.modalA ::v-deep .ant-modal-footer {
  border-top: 0px solid #e8e8e8;
}
.modalBox {
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.pricing-method {
  margin-bottom: 10px;
}
</style>
